<div class="container">
  <div *ngIf="page1">
  <div class="text-center h3">REGISTRY PERSONNEL ACCESS APPLICATION</div>
  <div class="text-left mt-3">To gain access to this system, you must complete the following information and an administrator
  will authorize your access to the areas that you are authorized access to.</div>

  <div class="row mt-4">
    <div class="col-4 d-flex justify-content-center align-content-center" style="border-radius: 15px 0px 0px 15px; background-color: #0A3069; height: 400px">
      <div class="text-center align-self-center text-white h1 font-weight-bold">NEW ACCESS REQUEST</div>
    </div>
    <div class="col-8 border border-dark">
      <div class="form-group">
        <label for="displayName">Full Name</label>
        <input [(ngModel)]="displayName" type="text" id="displayName" class="form-control">
      </div>
      <div class="form-group">
        <label for="emailaddress">Email Address (Will Be Verified)</label>
        <input [(ngModel)]="email" type="text" id="emailaddress" class="form-control">
      </div>
      <div class="form-group">
        <label for="password1">Password</label>
        <input [(ngModel)]="password" type="password" id="password1" class="form-control">
      </div>
      <div class="form-group">
        <label for="password2">Retype Password</label>
        <input [(ngModel)]="password2" type="password" id="password2" class="form-control">
      </div>
      <div class="text-right">
        <div class="btn btn-success btn-sm" (click)="onSubmitForm()">SUBMIT</div>
      </div>
    </div>
  </div>
  </div>
  <div *ngIf="page2">

  </div>
</div>
