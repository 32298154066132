import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Users} from '../../../models/users';
import * as moment from 'moment';
import {UsersService} from '../../../services/users.service';




@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  @Input() userID: Users;
  @Output() exit = new EventEmitter<boolean>();

  currentUserData: Users;

  constructor(private user: UsersService) { }

  ngOnInit(): void {
    if (this.userID.displayName === '' || this.userID.displayName == null) { this.userID.displayName = 'NO DISPLAY NAME SET'; }
    this.currentUserData = {};
    console.log('CurrentUser: ', this.currentUserData);
    this.user.getUserInfoByID(this.userID.email).subscribe((u: Users[]) => {
      console.log('GetUsers: ', u);
      this.currentUserData = u[0];
    });
  }

  doExit(): void {
    this.exit.emit(true);
  }

  getAge(date): string {
    if (date === '' || date == null) { return 'NOT AVAILABLE'; }
    const d = moment(date);
    return d.fromNow() + ' on ' + d.format('MMM Do yyyy');
  }

}
