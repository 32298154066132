<div class="container mb-5">
  <div class="row mt-2" style="background-color: #F8BD28; padding: 10px; border-radius: 10px; border: 2px black solid">
    <div class="col-10">
      <div class="h3 text-white font-weight-bold">USER ADMINISTRATION</div>
    </div>
    <div class="col-2 text-right pt-1">
      <div class="btn btn-sm btn-danger" (click)="doExit()">EXIT</div>
    </div>
  </div>
  <div class="mt-2">
    <div class="row h3 font-weight-bold p-2" style="background-color: #0A3069; color: white; border-radius: 10px">
      Authentication record:
    </div>
    <div class="row mt-2">
      <div class="col-2 border border-dark rounded-left p-1">
        <span style="font-size: 9px; font-weight: bold">DISPLAY NAME</span><br>
        <span style="font-size: 11px; font-weight: bold">{{userID.displayName}}</span><br>
      </div>
      <div class="col-4 border border-dark p-1">
        <span style="font-size: 9px; font-weight: bold">EMAIL (USER ID)</span>
        &nbsp;<span *ngIf="!userID.disabled" style="font-size: 9px" class="badge badge-pill badge-success">ACCOUNT ACTIVE</span>
        &nbsp;<span *ngIf="userID.disabled" style="font-size: 9px" class="badge badge-pill badge-danger">ACCOUNT DISABLED</span><br>
        <span style="font-size: 11px; font-weight: bold">{{userID.email}}</span>
        &nbsp;<span *ngIf="userID.emailVerified" style="font-size: 9px" class="badge badge-pill badge-success">EMAIL VERIFIED</span>
        &nbsp;<span *ngIf="!userID.emailVerified" style="font-size: 9px" class="badge badge-pill badge-danger">EMAIL NOT VERIFIED</span>
      </div>
      <div class="col-3 border border-dark p-1">
        <span style="font-size: 9px; font-weight: bold">ACCOUNT CREATION DATE</span><br>
        <span style="font-size: 11px; font-weight: bold">{{getAge(userID.metadata.creationTime)}}</span><br>
      </div>
      <div class="col-3 border border-dark rounded-right p-1">
        <span style="font-size: 9px; font-weight: bold">LAST LOGIN TIME</span><br>
        <span style="font-size: 11px; font-weight: bold">{{getAge(userID.metadata.lastSignInTime)}}</span><br>
      </div>
    </div>
  </div>
  <div class="row mt-1 rounded" style="background-color: #F8BD28">
    <div class="col-2 text-white pt-1 pl-1 h4 font-weight-bold">CONTROLS >></div>
    <div class="col-10 text-right">
      <div *ngIf="userID.disabled" class="btn btn-success btn-sm m-1">ENABLE ACCOUNT</div>
      <div *ngIf="!userID.disabled" class="btn btn-success btn-sm m-1">DISABLE ACCOUNT</div>
      <div *ngIf="!userID.emailVerified" class="btn btn-success btn-sm m-1">RESEND VERIFICATION EMAIL</div>
      <div class="btn btn-success btn-sm m-1">LOG USER OUT</div>
      <div class="btn btn-success btn-sm m-1">RESET PASSWORD</div>
    </div>
  </div>
  <div class="row mt-2 h3 font-weight-bold p-2" style="background-color: #0A3069; color: white; border-radius: 10px">
    Role Authorizations Record:
  </div>
  <div *ngIf="currentUserData.email" class="row mt-1">
    <div class="col-2 border border-dark rounded-left font-weight-bold">
      <span style="font-size: 9px">Admin Privileges</span>&nbsp;
      <span *ngIf="!currentUserData.isAdmin" class="badge badge-success" style="font-size: 9px">Grant</span>
      <span *ngIf="currentUserData.isAdmin" class="badge badge-danger" style="font-size: 9px">Revoke</span><br>
      <span style="font-size: 11px">{{currentUserData.isAdmin?'TRUE':'FALSE'}}</span>
    </div>
    <div class="col-2 border border-dark font-weight-bold">
      <span style="font-size: 9px">Registrar Privileges</span>&nbsp;
      <span *ngIf="!currentUserData.isRegistrar" class="badge badge-success" style="font-size: 9px">Grant</span>
      <span *ngIf="currentUserData.isRegistrar" class="badge badge-danger" style="font-size: 9px">Revoke</span><br>
      <span style="font-size: 11px">{{currentUserData.isRegistrar?'TRUE':'FALSE'}}</span>
    </div>
    <div class="col-2 border border-dark  font-weight-bold">
      <span style="font-size: 9px">Court Clerk Privileges</span>&nbsp;
      <span *ngIf="!currentUserData.isClerk" class="badge badge-success" style="font-size: 9px">Grant</span>
      <span *ngIf="currentUserData.isClerk" class="badge badge-danger" style="font-size: 9px">Revoke</span><br>
      <span style="font-size: 11px">{{currentUserData.isClerk?'TRUE':'FALSE'}}</span>
    </div>
    <div class="col-2 border border-dark font-weight-bold">
      <span style="font-size: 9px">Police Privileges</span>&nbsp;
      <span *ngIf="!currentUserData.isPolice" class="badge badge-success" style="font-size: 9px">Grant</span>
      <span *ngIf="currentUserData.isPolice" class="badge badge-danger" style="font-size: 9px">Revoke</span><br>
      <span style="font-size: 11px">{{currentUserData.isPolice?'TRUE':'FALSE'}}</span>
    </div>
    <div class="col-2 border border-dark rounded-right font-weight-bold">
      <span style="font-size: 9px">General Access</span>&nbsp;
      <span *ngIf="!currentUserData.isAuthorized" class="badge badge-success" style="font-size: 9px">Grant</span>
      <span *ngIf="currentUserData.isAuthorized" class="badge badge-danger" style="font-size: 9px">Revoke</span><br>
      <span style="font-size: 11px">{{currentUserData.isAuthorized?'TRUE':'FALSE'}}</span>
    </div>
    <div class="col-2 border border-dark font-weight-bold">
      <span style="font-size: 9px">Pending</span>&nbsp;
      <span *ngIf="!currentUserData.isPending" class="badge badge-danger" style="font-size: 9px">User Verified</span>
      <span *ngIf="currentUserData.isPending" class="badge badge-success" style="font-size: 9px">Verify User</span><br>
      <span style="font-size: 11px">{{currentUserData.isPending?'TRUE':'FALSE'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="mt-2">
      <span class="h6 font-weight-bold text-danger">User Roles Explanation:</span><br>
      Admin: Gives administrative access throughout the system - most powerful position<br>
      Clerk: Gives access to the Court Clerk system for entering new offenders<br>
      Registrar: Gives access to read and review the Registry logs, make corrections and deletions<br>
      Police: Gives read access to Police and the ability to enter Geodata into the ArcGIS system.<br>
      General Access: This provides access to Offender Management, Reports, Forms and ArcGIS System <b>(Most Users Need)</b><br>
      Pending: Remove pending role when user has been verified.
    </div>
  </div>
</div>
