<div class="container">
  <div class="text-center">
    <div class="btn btn-sm btn-success m-1" (click)="doOffenderManagement()">
      <fa-icon [icon]="faOffManagement"></fa-icon>
      OFFENDER MANAGEMENT
    </div>
    <div class="btn btn-sm btn-success m-1" (click)="doSearch()">
      <fa-icon [icon]="faSearch"></fa-icon>
      SEARCH DATABASE
    </div>
    <div class="btn btn-sm btn-success m-1" (click)="doArcGIS()">
      <fa-icon [icon]="faMaps"></fa-icon>
      ArcGIS System
    </div>
    <div class="btn btn-sm btn-success m-1" (click)="doForms()">
      <fa-icon [icon]="faForms"></fa-icon>
      FORMS & REPORTS
    </div>
    <div class="btn btn-sm btn-warning m-1" (click)="doRegistryAdmin()">
      <fa-icon [icon]="faSettings"></fa-icon>
      REGISTRY ADMIN
    </div>
    <div class="btn btn-sm btn-danger m-1" (click)="doAdmin()">
      <fa-icon [icon]="faAdmin"></fa-icon>
      SYSTEM ADMIN
    </div>
  </div>
  <div class="h3 mt-3 font-weight-bold text-center">The Bahamas Electronic Sex Offender Registry</div>
  <div class="text-left font-weight-bold my-3">
    Welcome to The Bahamas Electronic Sex Offender Registry. This system has been designed to automate many of the day to day
    administration and monitoring tasks associated with the rules and regulations set forth in the 2019 Sex Offender Regulations.
  </div>
  <div *ngIf="roles.isPending == true" class="row p-2 my-2 border border-dark rounded-lg bg-danger">
    <div class="col-12 text-center text-white h3">
      YOUR ACCOUNT IS PENDING - ACCESS IS LIMITED
    </div>

  </div>
  <div class="row">
    <div class="col-4">
      <div class="border border-dark rounded-top shadow shadow-sm">
        <div class="bg-success text-white text-center p-2 font-weight-bold rounded-top">NOTIFICATIONS
          <span *ngIf="highlightToday" class="badge badge-pill badge-primary ml-2" style="cursor: pointer; font-size: 9px" (click)="todayNotifications()">TODAY</span>
          <span *ngIf="!highlightToday" class="badge badge-pill badge-danger ml-2" style="cursor: pointer; font-size: 9px" (click)="todayNotifications()">TODAY</span>
          <span *ngIf="highlightWeek" class="badge badge-pill badge-primary ml-2" style="cursor: pointer; font-size: 9px" (click)="weekNotifications()">WEEK</span>
          <span *ngIf="!highlightWeek" class="badge badge-pill badge-danger ml-2" style="cursor: pointer; font-size: 9px" (click)="weekNotifications()">WEEK</span>
          <span *ngIf="highlightMonth" class="badge badge-pill badge-primary ml-2" style="cursor: pointer; font-size: 9px" (click)="monthNotifications()">MONTH</span>
          <span *ngIf="!highlightMonth" class="badge badge-pill badge-danger ml-2" style="cursor: pointer; font-size: 9px" (click)="monthNotifications()">MONTH</span>
        </div>

        <div style="max-height: 300px; height: 300px; overflow-x: hidden; overflow-y: scroll">
          <div *ngFor="let note of currentNotifications" class="mt-1 p-2">
            <table width="100%" class="shadow shadow-sm" style="cursor: pointer">
              <tr>
                <td class="bg-success rounded-left pl-2" width="10%" height="100px">
                  <div class="text-center font-weight-bold" style="color: white; font-size: 16px; height: 100px; writing-mode: vertical-rl">{{getDateFromUnix(note.timestamp)}}</div>
                </td>
                <td style="background-color: antiquewhite; padding-left: 5px" width="90%" height="50px">
                  <span style="font-size: 13px"><b>{{note.title}}</b></span><br><span style="font-size: 12px; line-height: 1px">{{note.text}}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="border border-dark rounded-top shadow shadow-sm">
        <div class="bg-warning text-white text-left p-2 font-weight-bold rounded-top">NEEDS ATTENTION</div>
          <div style="max-height: 300px; height: 300px; overflow-x: hidden; overflow-y: scroll">
            <div class="mt-1 p-2">
              <table width="100%" class="shadow shadow-sm" style="cursor: pointer">
                <tr>
                  <td class="bg-warning rounded-left" width="10%" height="100px"></td>
                  <td style="background-color: antiquewhite; padding-left: 5px" width="90%" height="50px">
                    <span style="font-size: 13px"><b>TEST NOTICE</b></span><br><span style="font-size: 12px; line-height: 1px">This is a Test of the Needs Attention Alert Box</span>
                    <br><span class="small">(CLICK FOR OPTIONS)</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
      </div>
    </div>
    <div class="col-4">
      <div class="border border-dark rounded-top shadow shadow-sm">
        <div class="bg-danger text-white text-left p-2 font-weight-bold rounded-top">UPCOMING EVENTS</div>
        <div style="max-height: 300px; height: 300px; overflow-x: hidden; overflow-y: scroll">
          <div class="mt-1 p-2">
            <table width="100%" class="shadow shadow-sm" style="cursor: pointer">
              <tr>
                <td class="bg-danger rounded-left" width="10%" height="50px"></td>
                <td style="background-color: antiquewhite; padding-left: 5px" width="90%" height="100px">
                  <span style="font-size: 13px"><b>TEST EVENT</b></span><br><span style="font-size: 12px; line-height: 1px">This is a Test of the upcoming events alert system</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>
