import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LoginComponent} from './components/login/login.component';
import {CheckstatusComponent} from './components/checkstatus/checkstatus.component';
import {AngularFireAuthGuard, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {UpdateProfileComponent} from './components/update-profile/update-profile.component';
import {SignupformComponent} from './components/signupform/signupform.component';
import {AdminComponent} from './components/admin/admin.component';
import {ArcgisComponent} from './components/arcgis/arcgis.component';

const routes: Routes = [
  {path: '', component: CheckstatusComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AngularFireAuthGuard]},
  {path: 'update', component: UpdateProfileComponent, canActivate: [AngularFireAuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'signup', component: SignupformComponent},
  {path: 'arcgis', component: ArcgisComponent},
  {path: 'admin', component: AdminComponent, canActivate: [AngularFireAuthGuard]},
  { path: 'offman', loadChildren: () => import('./components/offenderManagement/offender-management/offender-management.module').then(m => m.OffenderManagementModule) },
  { path: '*', component: DashboardComponent, canActivate: [AngularFireAuthGuard]},
  { path: '**', component: DashboardComponent, canActivate: [AngularFireAuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
