import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import User = firebase.User;
import {AuthService} from '../../services/auth.service';
import {faAddressCard, faCog, faCogs, faEdit, faExclamationTriangle, faMap, faSearch} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {Users} from '../../models/users';
import {AngularFirestore} from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import {Notification, NotificationService} from '../../services/notification.service';
import * as moment from 'moment';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  user: Users;
  creds: User;
  faSettings = faCogs;
  faOffManagement = faAddressCard;
  faSearch = faSearch;
  faMaps = faMap;
  faForms = faEdit;
  faAdmin = faCog;
  faAttention = faExclamationTriangle;
  roles: Users = {
    isPending: false,
  };

  highlightToday = true;
  highlightWeek = false;
  highlightMonth = false;

  currentNotifications: Notification[] = [];

  constructor(private auth: AuthService, private router: Router, private fs: AngularFirestore, private ns: NotificationService) {
    auth.isLoggedIn().subscribe((cred) => {
      this.creds = cred as User;
      this.fs.collection('users', res => res.where('email', '==', this.creds.email)).valueChanges().subscribe((u) => {
        this.user = u[0];
        this.roles = u[0] as Users;
        const today = moment().subtract(1, 'day').unix();
        this.currentNotifications = this.ns.getNotifications(today);
      });
    });
  }

  ngOnInit(): void {

  }

  todayNotifications(): void {
    this.highlightToday = true;
    this.highlightWeek = false;
    this.highlightMonth = false;
    this.currentNotifications = [];
    const today = moment().subtract(1, 'day').unix();
    this.currentNotifications = this.ns.getNotifications(today);
  }

  weekNotifications(): void {
    this.highlightToday = false;
    this.highlightWeek = true;
    this.highlightMonth = false;
    this.currentNotifications = [];
    const today = moment().subtract(7, 'days').unix();
    this.currentNotifications = this.ns.getNotifications(today);
  }

  monthNotifications(): void {
    this.highlightToday = false;
    this.highlightWeek = false;
    this.highlightMonth = true;
    this.currentNotifications = [];
    const today = moment().subtract(1, 'month').unix();
    this.currentNotifications = this.ns.getNotifications(today);
  }

  getDateFromUnix(time): string {
    return moment.unix(time).format('MMM Do');
}

  doOffenderManagement(): void {
    if (!this.roles.isAuthorized) {
      Swal.fire({
        title: 'UNAUTHORIZED!',
        text: 'You are not authorized to enter this section of the Registry. Please see your systems administrator.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
    this.router.navigateByUrl('/offman');
  }

  doSearch(): void {
    if (!this.roles.isAuthorized) {
      Swal.fire({
        title: 'UNAUTHORIZED!',
        text: 'You are not authorized to enter this section of the Registry. Please see your systems administrator.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
  }

  doArcGIS(): void {
    if (!this.roles.isAuthorized) {
      Swal.fire({
        title: 'UNAUTHORIZED!',
        text: 'You are not authorized to enter this section of the Registry. Please see your systems administrator.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
    this.router.navigateByUrl('/arcgis');
  }

  doForms(): void {
    if (!this.roles.isAuthorized) {
      Swal.fire({
        title: 'UNAUTHORIZED!',
        text: 'You are not authorized to enter this section of the Registry. Please see your systems administrator.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
  }

  doRegistryAdmin(): void {
    if (!this.roles.isAuthorized) {
      Swal.fire({
        title: 'UNAUTHORIZED!',
        text: 'You are not authorized to enter this section of the Registry. Please see your systems administrator.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
  }

  doAdmin(): void {
    if (!this.roles.isAuthorized) {
      Swal.fire({
        title: 'UNAUTHORIZED!',
        text: 'You are not authorized to enter this section of the Registry. Please see your systems administrator.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
    this.router.navigateByUrl('/admin');
  }

}
