import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// Import Firestore Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFireStorageModule } from '@angular/fire/storage';
// Import Routing and Main App Component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Import Environment File
import { environment } from '../environments/environment.prod';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { CheckstatusComponent } from './components/checkstatus/checkstatus.component';
import {FormsModule} from '@angular/forms';
import {DragulaModule} from 'ng2-dragula';
import { UpdateProfileComponent } from './components/update-profile/update-profile.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SignupformComponent } from './components/signupform/signupform.component';
import { AdminComponent } from './components/admin/admin.component';
import { EditUserComponent } from './components/admin/edit-user/edit-user.component';
import { ArcgisComponent } from './components/arcgis/arcgis.component';
import { GoogleMapsModule} from '@angular/google-maps';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    CheckstatusComponent,
    UpdateProfileComponent,
    SignupformComponent,
    AdminComponent,
    EditUserComponent,
    ArcgisComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    NgbModule,
    FormsModule,
    DragulaModule.forRoot(),
    FontAwesomeModule,
    GoogleMapsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
