import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  userList: Array<any>;

  showUsers = true;
  showEdit = false;

  userID: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    const func = firebase.functions();
    const getUsers = func.httpsCallable('getAllUsers');
    getUsers().then((data) => {
      this.userList = data.data.users;
    }).catch(err => {
      console.log('Error Getting Users: ', err);
    });
  }

  doExit(): void {
    this.router.navigateByUrl('/dashboard');
  }

  doEditUser(userID: string): void {
    this.userID = userID;
    this.showEdit = true;
    this.showUsers = false;
  }

  closeEdit(event): void {
    this.userID = '';
    this.showEdit = false;
    this.showUsers = true;
    const func = firebase.functions();
    const getUsers = func.httpsCallable('getAllUsers');
    getUsers().then((data) => {
      this.userList = data.data.users;
    }).catch(err => {
      console.log('Error Getting Users: ', err);
    });
  }

}
