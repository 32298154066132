import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import firebase from 'firebase';
import {Router} from '@angular/router';
import User = firebase.User;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-signupform',
  templateUrl: './signupform.component.html',
  styleUrls: ['./signupform.component.css']
})
export class SignupformComponent implements OnInit {

  email: string;
  password: string;
  password2: string;
  displayName: string;
  page1 = true;
  page2 = false;
  cred: User;

  constructor(private router: Router, private auth: AngularFireAuth) {

  }

  ngOnInit(): void {
  }

  onSubmitForm(): void {
    this.auth.createUserWithEmailAndPassword(this.email, this.password2).then((creds) => {
      Swal.fire({
        title: 'ACCOUNT CREATED!',
        text: 'Your account has been created with email: ' + this.email + '. You are now logged in but have no access.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {

      }).catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: err,
          icon: 'error',
          confirmButtonText: 'OK'
        }).then(() => {
          this.router.navigateByUrl('/login');
        });
      });
    });
  }
}
