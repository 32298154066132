<div>
  <div class="row pl-3" style="height: 80vh; width: 100vw">
    <div class="col-2">
      <div class="font-weight-bold h4 mt-1 text-center">Graphical Information System</div>
      <div class="text-center mt-2">Click on a pin for management options</div>
      <div class="btn btn-success btn-block btn-sm" (click)="doExit()">EXIT TO MAIN</div>
    </div>
    <div class="col-10 pt-3">
      <google-map class="full" width="98%" height="100%" [zoom]="zoom" [center]="center" [options]="options">
        <map-marker #markerElem='mapMarker' *ngFor="let geo of geotags" (mapClick)="doMarkerClick(markerElem, geo.offenderID, geo.type)" [clickable]="true" [position]="getLatLong(geo)"></map-marker>
        <map-info-window></map-info-window>
      </google-map>
    </div>
  </div>
</div>

