<div class="position-relative" style="height: 17px; width: 100%; background-color: #F8BD28"></div>
<div style="height: 70px; width: 100%; background-color: #0A3069;">
  <div class="text-white text-left p-1 clearfix" style="margin-left: 100px">
    <div class="float-left">
      <span class="h3">BAHAMAS SEX OFFENDER</span><br>
      <span class="h5">REGISTRY MANAGEMENT SYSTEM</span>
    </div>
    <span *ngIf="cred != null" class="float-right mr-2 mt-4"><div class="btn btn-sm btn-primary" (click)="updateProfile()">UPDATE YOUR PROFILE</div></span>
  </div>
  <img class="position-absolute" style="top:3px; left: 10px; width:80px" src="../assets/coatofarms_sm.png">
</div>
<div class="text-right font-weight-bold pr-3" style="font-size: 10px; height: 17px; width: 100%; background-color: #F8BD28">
  <span *ngIf="cred != null">{{cred.displayName}} ({{cred.email}})</span>&nbsp;&nbsp;
  <span *ngIf="cred != null" class="badge badge-pill badge-danger" style="cursor: pointer" (click)="logout()">LOGOUT</span>
</div>
<router-outlet></router-outlet>
<div class="mt-5">&nbsp;</div>
<div style="position: fixed; bottom: 0px; left: 0px; right: 0px; width: 100%;">
  <div class="text-white text-right p-2 pr-4" style="width: 100%; background-color: #0A3069; font-size: 10px">
    &copy; 2021 Justice Technology Corp in Collaboration with Multimedia Technologies Updated August 12, 2021
  </div>
</div>
