import {Component} from '@angular/core';
import { AuthService } from './services/auth.service';
import firebase from 'firebase';
import User = firebase.User;
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  cred: User;

  constructor(private authService: AuthService, private router: Router) {
    console.clear();
    console.log('%cSEX OFFENDER REGISTRY ENCRYPTED DATA', 'color:red; font-size: 24px');
    console.log('%cThis is a restricted area. IP Addresses are logged. All data is End-To-End Encrypted. ' +
      'This system built and maintained by Justice Technology Corp (330) 519-1455', 'color:green; font-size: 20px');
    // authService.logout();
    authService.isLoggedIn().subscribe((cred) => {
      this.cred = cred as User;
    });
  }

  logout(): void {
    this.authService.logout();
  }

  updateProfile(): void {
    this.router.navigateByUrl('update');
  }
}
