import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import User = firebase.User;
import Swal from 'sweetalert2';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {
  displayName = '';
  userName = '';
  emailVerified = false;
  password1 = '';
  password2 = '';
  phoneNumber = '';
  creationDate = '';
  lastsignin = '';

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    const currentUser: User = firebase.auth().currentUser as User;
    this.displayName = currentUser.displayName;
    this.userName = currentUser.email;
    this.emailVerified = currentUser.emailVerified;
    this.phoneNumber = currentUser.phoneNumber;
    this.creationDate = currentUser.metadata.creationTime;
    this.lastsignin = currentUser.metadata.lastSignInTime;
  }
  updateDisplayName(): void {
    const currentUser: User = firebase.auth().currentUser as User;
    currentUser.updateProfile({
      displayName: this.displayName
    }).then(() => {
      Swal.fire({
        title: 'Display Name Updated',
        text: 'Your Display Name has been updated!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    }).catch((err) => {
      Swal.fire({
        title: 'Error Updating Display Name',
        text: err.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    });

  }

  updatePassword(): void {
    const currentUser: User = firebase.auth().currentUser as User;
    if (this.password1 !== '' && this.password2 !== '' && this.password1 === this.password2) {
      currentUser.updatePassword(this.password2).then(() => {
        Swal.fire({
          title: 'Password Updated',
          text: 'Your password has been updated! You must sign back in with new password!',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          this.auth.logout();
        });
      }).catch(err => {
        Swal.fire({
          title: 'Error with password!',
          text: err.message,
          icon: 'error',
          confirmButtonText: 'OK'
        });
        this.password1 = '';
        this.password2 = '';
      });
    }
  }

  updateUserName(): void {

    const currentUser: User = firebase.auth().currentUser as User;
    currentUser.verifyBeforeUpdateEmail(this.userName).then(() => {
      Swal.fire({
        title: 'Email Sent',
        text: 'A Verification Email has been sent. You must click on the link inside the email before the new email will' +
          ' take effect. You will now be logged out!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        this.auth.logout();
      });
    }).catch(err => {
      Swal.fire({
        title: 'Error with email update!',
        text: err.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    });
  }
  returnDashboard(): void {
    this.router.navigateByUrl('dashboard');
  }

}
