<div class="container">
  <div class="row main-content bg-success text-center">
    <div class="col-md-4 text-center company__info">
      <span class="company__logo">
        <img src="../../../assets/coatofarms_sm.png" class="img-fluid">
      </span>
    </div>
    <div class="col-md-8 col-xs-12 col-sm-12 login_form ">
      <div class="container-fluid">
        <div class="row">
          <div class="text-center" style="width: 100%"><h2>Registry Login</h2></div>
        </div>
        <div class="row">
          <h6>This is a restricted site. You must have authorization to access. Your IP address is being logged.</h6>
        </div>
        <div class="row">
          <form control="" class="form-group">
            <div class="row">
              <input [(ngModel)]="username" type="text" name="username" id="username" class="form__input" placeholder="Username">
            </div>
            <div class="row">
              <!-- <span class="fa fa-lock"></span> -->
              <input [(ngModel)]="password" type="password" name="password" id="password" class="form__input" placeholder="Password">
            </div>
            <div class="row">
              <input type="submit" value="Submit" class="btn" (click)="login()">
            </div>
          </form>
        </div>
        <div class="row">
          <p>Don't have an account? <a routerLink="/signup">Register Here</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
