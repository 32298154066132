import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import * as moment from 'moment';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private fs: AngularFirestore) { }

  addNotification(notificationTitle: string, notificationText: string): any {
    const note: Notification = {
      title: notificationTitle,
      text: notificationText,
      timestamp: moment().unix()
    };
    this.fs.collection('notifications').add(note).then(() => {
      return true;
    }).catch((err) => {
      return false;
    });
  }

  getNotifications(timestamp: any): any {
    const notifications: Notification[] = [];

    this.fs.collection('notifications', res => res.where('timestamp', '>=', timestamp))
      .valueChanges()
      .pipe(take(1))
      .subscribe((n: Notification[]) => {
        n.map((nn) => {
          notifications.push(nn);
        });
        notifications.sort((a, b) => {
          return b.timestamp - a.timestamp;
        });
      });

    return notifications;
  }

}

export class Notification  {
  timestamp: number;
  text: string;
  title: string;
}
