import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

import firebase from 'firebase';
import User = firebase.User;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private auth: AngularFireAuth, router: Router) {

    auth.authState.subscribe((cred) => {
      router.navigateByUrl('/dashboard');
      if (cred == null) {
        router.navigateByUrl('/login');
        Swal.fire({
          title: 'You Must Login',
          text: 'Please login to the Registry using your username and password.',
          icon: 'info',
          confirmButtonText: 'Proceed!'
        });
      } else {
        router.navigateByUrl('/dashboard');
      }
    });
  }

  login(username: string, password: string): void {
    this.auth.signInWithEmailAndPassword(username, password).then((creds) => {
      Swal.fire({
        title: 'Your Are logged in',
        text: 'Access has been granted and a secure connection has been established using End to End Encryption Technology',
        icon: 'success',
        confirmButtonText: 'Proceed!'
      });
    }).catch((err) => {
      Swal.fire({
        title: 'Error Logging In',
        text: err.message,
        icon: 'error',
        confirmButtonText: 'Proceed!'
      });

    });
  }

  logout(): void {
    this.auth.signOut();
  }

  isLoggedIn(): Observable<any> {
    return this.auth.authState;
  }
}
