import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Offenders} from '../../models/offenders';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';
import {take} from 'rxjs/operators';
import * as moment from 'moment';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './arcgis.component.html',
  styleUrls: ['./arcgis.component.css']
})
export class ArcgisComponent implements OnInit, AfterViewInit, OnDestroy {
// @ts-ignore
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  // @ts-ignore
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;


  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 8,
  };

  geotags: Geotags[] = [];
  markerText: string;

  subscription: any;

  constructor(private fs: AngularFirestore, private router: Router) {
  }

  ngOnInit(): void {
    this.center = {
      lat: 25.032728,
      lng: -77.397865
    };

    this.subscription = this.fs.collection('geotags')
      .valueChanges()
      .subscribe((geo: Geotags[]) => {
        this.geotags = geo;
      });

  }

  ngAfterViewInit(): void {


  }

  doMarkerClick(markerElem: MapMarker, offenderID: string, pinType: string): void {

    this.fs.collection('offenders').doc(offenderID)
      .valueChanges()
      .pipe(take(1))
      .subscribe((off: Offenders) => {
        this.info.options = {
          content: `
        <div class='row' style="width: 550px">
            <div class="col-4">
                <img src='${off.photo}' style="object-fit: cover; width: 150px; height: 220px; border: 5px red solid" class="rounded-lg">
            </div>
            <div class="col-8">
                <div class="text-danger font-weight-bold h4">SEX OFFENDER INFORMATION</div>
                <div class="font-weight-bold h5">${off.lastName}, ${off.firstName} ${off.middleName}</div>
                <div class="font-weight-bold h6"> <b>Date of Birth: </b>${moment(off.dob).format('MMM Do, YYYY')}</div>
                <div class="font-weight-bold text-primary">PRIMARY ADDRESS</div>
                <div class="">${off.primaryAddress.address1} ${off.primaryAddress.address2}<br>
                ${off.primaryAddress.city} ${off.primaryAddress.island}</div>
                <div class="font-weight-bold text-primary">SECONDARY ADDRESS</div>
                <div class="">${off.secondaryAddress.address1} ${off.secondaryAddress.address2}<br>
                ${off.secondaryAddress.city} ${off.secondaryAddress.island}</div>
                <div class="font-weight-bold text-danger mt-2">This pin represents the offender's ${pinType} Address</div>
                <div class="font-weight-bold text-primary text-center mt-3">VIEW OFFENDER IN OFFENDER MANAGEMENT</div>
            </div>
        </div>`,
          maxWidth: 550
        };
        this.info.open(markerElem);
      });


  }

  doCloseInfoWindow(event): void {
    console.log('Closed: ', event);
  }

  doExit(): void {
    this.router.navigateByUrl('/dashboard');
  }

  getLatLong(geo): any {

    return {
      lat: geo.lat,
      lng: geo.long
    };

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

interface Geotags {
  id?: string;
  offenderID?: string;
  date?: number;
  lat?: number;
  long?: number;
  type?: string;
}
