<div class="container">
  <div *ngIf="showUsers">
  <div class="row mt-2" style="background-color: #F8BD28; padding: 10px; border-radius: 10px; border: 2px black solid">
    <div class="col-10">
      <div class="h3 text-white font-weight-bold">ADMINISTRATION FUNCTIONS</div>
    </div>
    <div class="col-2 text-right pt-1">
      <div class="btn btn-sm btn-danger" (click)="doExit()">EXIT</div>
    </div>
  </div>

  <div class="row my-1">
    <div class="col-5 p-2 border border-white" style="background-color: bisque">
      <div class="text-left font-weight-bold">USER EMAIL<br>STATUS</div>
    </div>
    <div class="col-5 p-2 border border-white" style="background-color: bisque">
      <div class="text-left font-weight-bold">USERS DISPLAY NAME<br>ACCOUNT CREATION DATE</div>
    </div>
    <div class="col-2 p-2 border border-white" style="background-color: bisque">
      <div class="text-center font-weight-bold">ACTIONS</div>
    </div>
  </div>
  <div class="text-left" *ngFor="let user of userList">
    <div class="row my-1">
      <div class="col-5 p-2 border border-white" style="background-color: antiquewhite">
        <div class="text-left font-weight-bold">
          {{user.email}}<br>
          <span *ngIf="user.emailVerified" class="badge badge-pill badge-success m-1">Email Verified</span>
          <span *ngIf="!user.emailVerified" class="badge badge-pill badge-danger m-1">Email Not Verified</span>
          <span *ngIf="user.disabled" class="badge badge-pill badge-danger m-1">Account Disabled</span>
          <span *ngIf="!user.disabled" class="badge badge-pill badge-success m-1">Account Active</span>
        </div>
      </div>
      <div class="col-5 p-2 border border-white" style="background-color: antiquewhite">
        <div class="text-left"><b>{{user.displayName}}</b><br>Account Created: {{user.metadata.creationTime}}</div>
      </div>
      <div class="col-2 p-2 border border-white" style="background-color: antiquewhite">
        <div *ngIf="user.disabled" class="btn btn-sm btn-success m-1">ENABLE</div>
        <div *ngIf="!user.disabled" class="btn btn-sm btn-warning m-1">DISABLE</div>
        <div class="btn btn-sm btn-success m-1" (click)="doEditUser(user)">EDIT</div>
      </div>
    </div>
  </div>
  </div>
</div>
<app-edit-user *ngIf="showEdit" [userID]="userID" (exit)="closeEdit($event)"></app-edit-user>
