<div class="mx-2 my-3">
  <div class="clearfix p-3" style="background-color: #F8BD28; border: 3px solid #0A3069; border-radius: 15px;">
    <div class="float-left h3 font-weight-bold">User Profile Information Updates</div>
    <div class="float-right btn btn-danger btn-sm" (click)="returnDashboard()">FINISHED</div>
  </div>
</div>

<div class="container mt-1 p-2">
  <div class="row">
    <div class="d-flex justify-content-center align-content-center col-3 border border-dark" style="border-radius: 20px 0px 0px 20px; background-color: #0A3069">
      <div class="align-self-center text-white text-center h2">UPDATE DISPLAY NAME</div>
    </div>
    <div class="col-9 border border-dark">
      <div class="h4 font-weight-bold">Display Name:</div>
      <div class="text-left">Please update this name to reflect your actual name. This name is used throughout
      the system and is shown to other users in the registry.</div>
      <label for="displayName" class="font-weight-bold">Type New Display Name here:</label>
      <div class="input-group mb-1">
        <input [(ngModel)]="displayName" type="text" id="displayName" class="form-control">
        <div class="input-group-append btn btn-success" (click)="updateDisplayName()">UPDATE</div>
      </div>
    </div>

  </div>
  <div class="row mt-2">
    <div class="d-flex justify-content-center align-content-center col-3 border border-dark" style="border-radius: 20px 0px 0px 20px; background-color: #0A3069">
      <div class="align-self-center text-white text-center h2">USERNAME & PASSWORD</div>
    </div>
    <div class="col-9 border border-dark">
      <div class="h4 font-weight-bold">User Name (email):</div>
      <div class="text-left">The User Name (email) and Password are used to login to the system.
      If you need to use a different email to login to the system with, you may change it here at any time.</div>
      <label for="displayName" class="font-weight-bold">Type New Email (Will be verified):</label>
      <div class="input-group mb-1">
        <input [(ngModel)]="userName" type="text" id="userName" class="form-control">
        <div class="input-group-append btn btn-success" (click)="updateUserName()">UPDATE</div>
      </div>
      <div *ngIf="emailVerified" class="text-small text-success">Your User Name has been verified ({{userName}}) - Thank You<br>
      <span class="small text-muted">This profile was created on {{creationDate}} and you last signed in on {{lastsignin}}.</span></div>
      <div *ngIf="!emailVerified" class="text-small text-danger">Please verify your email. You will have limited functions until email address has been verified.</div>
      <hr width="80%">
      <div class="h4 font-weight-bold">Password:</div>
      <div class="text-left mt-2">Your password may need to be changed from time to time. Use this form to change your password</div>
      <label for="password" class="font-weight-bold">Type New Password:</label>
      <input [(ngModel)]="password1" type="text" id="password" class="form-control mb-1">
      <label for="password2" class="font-weight-bold">Verify New Password:</label>
      <div class="input-group mb-2">
        <input [(ngModel)]="password2" type="text" id="password2" class="form-control">
        <div class="input-group-append btn btn-danger" (click)="updatePassword()">UPDATE</div>
      </div>

    </div>

  </div>
  <div class="mb-5"></div>

</div>
